<template>
  <div>
    
    <div style="padding-inline-end:20px;">
        <v-row class="mt-3" :style="`direction:`+lang.dir+`;`">
            
            <v-col cols="12" md="3" sm="6">
                <label>{{lang.start_date}}</label>
                    <b-input-group>
                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.day" :options="daysList" />
                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;border-right:none !important;" v-model="ed.month" :options="monthsList" />
                        <b-form-select class="inborder text-center" style="width:33%;background:#c3d9ff;" v-model="ed.year" :options="yearsList" />
                </b-input-group>
            </v-col>
            <v-col cols="12" md="2" sm="6">
                <label>{{ lang.employee_name }}</label>
                <b-input-group>
                    <b-form-select class="selborder text-center" v-model="userid" :options="agentsList" />
                </b-input-group>
            </v-col>
            <!-- <v-col cols="12" md="2" sm="6">
                <label>حالة المهام</label>
                <b-input-group>
                    <b-form-select class="selborder text-center" />
                </b-input-group>
            </v-col> -->
            <v-col cols="12" md="2" sm="12">
                <label></label>
                <b-input-group style="margin-top:-5px">
                    <b-button variant="light" style="background:blue;color:#fff;width:75px;"  @click="getDaysList()">{{lang.search}}</b-button>
                </b-input-group>
            </v-col>
            <v-col cols="12" md="5" sm="12" class="text-end">
                <b-button
                    @click="addTask()"
                    v-b-modal.add_multi_task
                    class="btn-sm btn"
                    style="margin-top:7px;width:150px;background:transparent;border:none !important;margin-left:-7px !important;"
                    id="multiply-button" 
                    >
                    <div :class="`multiply-button-content-`+lang.lalgin" style="width:150px;border:none !important">{{lang.add_task}}</div>
                    
                    </b-button>
            </v-col>
            <v-col md="12" style="width:100%;overflow:auto;">
                <div class="FlexRow" style="width:max-content">
                    <div class="newCalendar" style="border-inline-start:2px solid #0099ff;width:250px;" v-for="(value, key, indo) in days" :key="indo">
                        <h6 class="text-center backBlack p-1">
                            {{ (new Date(key)).toLocaleDateString("ar-sa",{weekday : 'long'}) }} {{ key }}
                        </h6>
                        <div class="m-1" style="min-height:calc(100vh - 300px)">
                            <div class="text-center mt-2" style="padding:2px;" v-for="(item,keys,index) in days[key]" :key="index">
                                <div class="backRed">{{ lang.thHour }} - {{ keys }}</div>
                                <div class="taskRows mt-1" v-for="(itm,ind) in item" :key="ind" style="cursor:pointer" @click="openMe(itm.id,itm.type.title)" v-b-modal.open_task>
                                    <div>{{ itm.task_title }} </div>
                                    <div class="servIce">
                                        <div :style="`font-size:.7rem;border:1px solid #fff;width:50%;`">{{ itm.time }}</div>
                                        <div :style="`font-size:.7rem;border:1px solid #fff;width:50%;${itm.type.style}`">{{ lang[itm.type.title] }}</div>
                                        <div :style="`font-size:.7rem;border:1px solid #fff;width:50%;${itm.proc.style}`">{{ itm.proc.title[`name_${lang.langname}`] }}</div>
                                        <div :style="`font-size:.7rem;border:1px solid #fff;width:50%;${itm._status.style}`">{{ itm._status.title }}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
    <add-multi-task ref="addMultiTask" />
    <Footer style="display:none;" />
    <div class="loadingPage" v-if="$store.state.showLoading">
      <v-progress-circular
          :width="3"
          color="green"
          indeterminate
      ></v-progress-circular>
      </div>
      <vue-snotify></vue-snotify>
  </div>
</template>

<script>
  import Vue from 'vue'
  import axios from 'axios'
//   import BreadCrumbs from '@/components/breadCrumbs.vue'
//   import rightMenu from '@/components/newDesign/RightMenu.vue'
  import Footer from '@/components/Footer.vue'
  // import ExportsPrint from '@/components/exports-print.vue'
  import addMultiTask from '@/components/newDesign/addMultiTask.vue'
//   import {SnotifyPosition} from 'vue-snotify'
  export default Vue.extend({
    name: 'Home',

    components: {
        addMultiTask,
        // rightMenu,
        Footer,
        // TabsComp,
    },
    data() {
      return {
        userid: '',
        agents: [],
        cpage: 0,
        showLoading: false,
        search: '',
        drow: false,
        excel_fields:{},
        ishome: true,
        loading: false,
        sd: {
          day: 1,
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear() - 1,
        },
        ed: {
          day: (new Date()).getDate(),
          month: (new Date()).getMonth() + 1,
          year: (new Date()).getFullYear(),
        },
        days: [],
        taskRows: {}
      }
    },
    created() {
      
        this.getDaysList();
        this.getSettings();
    },
    methods: {
      addTask(){
        this.$refs.addMultiTask.reset();
      },
      openMe(id,type){
        this.$refs.UpdateCalendarTask.crm_id = id;
        this.$refs.UpdateCalendarTask.type = type;
        this.$refs.UpdateCalendarTask.getTaskDet();
      },
      getCus(id){
          this.$refs.customerRef.customerid = id;
          this.$refs.customerRef.getCustomerInfo();
      },
      editSupport(item) {
          console.log("task" ,item);
          this.$refs.UpdateSamSupport.crm_id = item.id;
          this.$refs.UpdateSamSupport.task_response = item.userid;
          this.$refs.UpdateSamSupport.status = item.status;
          this.$refs.UpdateSamSupport.notes = '';
          this.$refs.UpdateSamSupport.getTaskDet();
      },
      editTask(item) {
        this.$refs.UpdateSamTask.crm_id = item.id;
        this.$refs.UpdateSamTask.task_response = item.userid;
        this.$refs.UpdateSamTask.status = item.status;
        this.$refs.UpdateSamTask.notes = '';
        this.$refs.UpdateSamTask.getTaskDet();
        this.$refs.UpdateSamTask.show()
            
      },
      getDaysList(){
        const post = new FormData();
        post.append("type", "getDaysList");
        post.append("auth",this.$cookies.get(this.$store.state.COOKIEPhase));
        post.append("data[id]",1)
        post.append("data[edate]",this.edate)
        axios.post(
            this.$SAMCOTEC.r_path,post
        ).then((response) => {
            this.days = response.data.results.data;
        })
      },
      getSettings() {
          const post = new FormData();
          post.append("type","addTaskSettings");
          post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
          post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
          post.append('data[id]',0);
          axios.post(
              this.$store.state.SAMCOTEC.r_path, post
          ).then((response) => {
              if(response && response.data && response.data.results){
                  this.agents = response.data.results.agents
                  this.taskProcedure = response.data.results.taskProcedure
                  this.innerProcedure = response.data.results.innerProcedure
                  this.bookingType = response.data.results.bookingType
              }
          });
      },
    },
    computed: {
        dayHours: function() {
            let t = []
            // for(let i=this.$store.state.startTime;i<this.$store.state.endTime;i++){
            //     if(this.taskRows[i].length > 0)
            //         t.push({
            //             hour: i,
            //             tasks: this.taskRows[i]
            //         })
                
            // }
            return t;
        },
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        isMenu: function(){
          console.log("menu",this.$refs.HeaderPortrate.isMenu);
          if(this.$refs.HeaderPortrate.isMenu) return true;
          return false;
        },
        agentsList: function(){
          let t = []
            for(let i=0;i<this.agents.length;i++){
                t.push({text: this.agents[i].full_name, value: this.agents[i].id})
            }
            return t;
        },
        sdate: function() {
          return (new Date(this.sd.year,this.sd.month-1, this.sd.day+1)).toISOString().substring(0,10);
        },
        edate: function() {
          return (new Date(this.ed.year,this.ed.month-1, this.ed.day+1)).toISOString().substring(0,10);
        },
        yearsList: function(){
          const start_year = this.$store.state.licenseType.startYear;
          let t = [{text: this.lang.year, value: -1}];
          for(let i=0;i<100;i++){
            if(parseInt(start_year) + i <= (new Date()).getFullYear()){
              t.push({text: start_year + i, value: start_year+i})
            }
            
          }
          return t
        },
        daysList: function(){
          return [
            {text: this.lang.day, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
            {text: 13, value: 13},
            {text: 14, value: 14},
            {text: 15, value: 15},
            {text: 16, value: 16},
            {text: 17, value: 17},
            {text: 18, value: 18},
            {text: 19, value: 19},
            {text: 20, value: 20},
            {text: 21, value: 21},
            {text: 22, value: 22},
            {text: 23, value: 23},
            {text: 24, value: 24},
            {text: 25, value: 25},
            {text: 26, value: 26},
            {text: 27, value: 27},
            {text: 28, value: 28},
            {text: 29, value: 29},
            {text: 30, value: 30},
            {text: 31, value: 31},
          ]
        },
        monthsList: function(){
          return [
            {text: this.lang.chose_month, value: -1},
            {text: 1, value: 1},
            {text: 2, value: 2},
            {text: 3, value: 3},
            {text: 4, value: 4},
            {text: 5, value: 5},
            {text: 6, value: 6},
            {text: 7, value: 7},
            {text: 8, value: 8},
            {text: 9, value: 9},
            {text: 10, value: 10},
            {text: 11, value: 11},
            {text: 12, value: 12},
          ]
        },

      items:function() {
          let title = this.$store.state.hometitle;
          const lang = this.$cookies.get(this.$store.state.LangCooki);
          if(lang == "en"){
            title = this.$store.state.hometitle_en;
          }
          return {
            text: title,
            disabled: true,
            href: '/home',
          }
      },

      tabs: function() {
          const menu = this.$store.getters['state'].menu;
            const license = this.$store.getters['state'].licenseType.activemenu;
            const alicense = this.$store.getters['state'].licenseType
            let currentMenu = menu[license];
            if(!license){
              return false;
            }
            let cMenu = {main: {},sub:[]};
            Object.values(currentMenu).forEach(value =>{
            const cM = localStorage.getItem('currentMenu');
            if(value.main.index == cM){
                cMenu = value.sub;
            }else if(cM == null && value.main.index == 1){
                cMenu = value.sub;
            }
            });
        
            let t = []
            Object.values(cMenu).forEach(value =>{
            
                if(this.$route.path == value.href){
                    value.class = "mytab";
                }else{
                    value.class = "mytab4";
                }
                if(this.lang.langname == 'ar'){
                    value.name = value.arname
                }else{
                    value.name = value.enname
                }
                for(let i =0; i< value.groupid.length;i++){
                    if(value.groupid[i] == this.$store.getters['state'].group_id){
                    if(alicense[value.perms[0]] || value.perms[0] == 'any')
                        t.push(value)
                    }
                }
            
            })
        
            return t;
      }
    },
  })
</script>
<style>
.myRow{
    margin:auto;
    margin-top:10px;
    display:flex;
    flex-direction:row;
    align-items: stretch;
    justify-content:space-around;
    justify-items:center;
}
.v-data-table-header__icon{
  display:none !important;
}
.outsearch{
  color:green;
  border:1px solid #ccc;
}
thead th, tfoot th, .tfoot th{
  background: #c3d9ff !important;
  color:#000 !important;
  border:1px solid #000 !important;
  font-size:12px;
  white-space: nowrap;
}
thead th a , thead th i, thead th i a{
  color:#FFF !important;
  white-space: nowrap;
}
tbody td{
  font-size:12px;
  color:#000 !important;
  white-space: nowrap;
  border:1px solid #000;
}
.v-breadcrumbs{
  padding:12px !important;
}
.v-tab--active{
  background:#ccc;
  color:#FFF;
}
.v-tabs-slider-wrapper a{
  color:#FFF !important;
}
tfoot th{
  border:1px solid #000;
}
.close svg {
    width: 74px !important;
    height: 34px !important;
    color: #FFF;
    background: red;
}
header button.close {
    width: 24px !important;
    height: 24px !important;
}
input{
  box-shadow: none !important;
}
button:active,button:focus{
  box-shadow: none !important;
}
._mybtn{
  font-size:.8rem;
  border:0px solid #fff;
  width:100%;
}
._mybtn2{
  font-size:.8rem;
  background:rgb(15, 212, 230) !important;
  border:0px solid #000;
  width:100%;
}
._mybtn4{
  font-size:.8rem;
  background:rgb(15, 90, 230) !important;
  border:0px solid #fff;
}
._mybtn3{
  font-size:.8rem;
  background:#198754 !important;
  border:0px solid #fff;
}
._mybtn1{
  font-size:.8rem;
  background:#fe7b33 !important;
  border:0px solid #fff;
}
td,th{
      height: 30px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th{
  padding: 0 10px !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td, .v-data-table > .v-data-table__wrapper > table > tbody > tr > th, .v-data-table > .v-data-table__wrapper > table > thead > tr > td, .v-data-table > .v-data-table__wrapper > table > thead > tr > th, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td, .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
    padding: 0 10px !important;
}
.viewBTN{
  font-size:.8rem;
  background:blue !important;
  width:100%;
  color:#fff !important;
  height:25px !important;
  margin-left:5px;
  text-align:center;
  display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.viewBTN:hover,.viewBTN:active,.viewBTN.viewBTN:click{
  background:blue !important;
  width:100%;color:#fff !important;
}
.backBlue{
  background: blue !important;
  color: #fff !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.backGold{
  background: gold !important;
  color: #000 !important;
  border-bottom: 1px solid #fff !important;
  border-left: 1px solid #fff !important;
  border-right: 1px solid #fff !important;
}
.viewBTN{
  font-size:.8rem;
  background:blue !important;
  width:100%;
  color:#fff !important;
  height:25px !important;
  margin-left:5px;
  text-align:center;
  display:flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
}
.viewBTN:hover,.viewBTN:active,.viewBTN.viewBTN:click{
  background:blue !important;
  width:100%;color:#fff !important;
}
.loadingPage{
  position: fixed;
  left:0;
  right:0;
  top:0;
  bottom: 0;
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: center;
  background:#ffffff50;
  z-index: 100000;
}
.flexRowIt{
    display:flex;
    flex-wrap:wrap;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
}
.FlexRow{
    display:flex;
    flex-wrap:nowrap;
    flex-direction: row;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
}
.flexColIt{
    display:flex;
    flex-wrap:wrap;
    flex-direction: column;
    justify-content: center;
    justify-items: center;
    align-items: stretch;
}
.taskRows{
    border: 1px solid #000;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
}
.servIce{
    display:flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
}
</style>

