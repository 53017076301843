<template>
    <v-form ref="forcar" lazy-validation>
        <b-modal
            id="add_multi_task"
            ref="modal"
            hide-footer
            hide-header
            size="md"
            :footer-bg-variant="`warning`"
            :header-bg-variant="`dark`"
            :header-text-variant="`light`"
            lazy
            :style="`direction:${lang.dir}`"
        >
        <template #default="{ hide }">
          <div class="m-1 text-start" style="direction: rtl">
            <div
              style="
                width: 120px;
                margin-right: 10px;
                background: #000;
                color: #fff;
                border-radius: 5px;
              "
              class="btn-primary btn-sm text-center"
            >
              <span>{{ lang.add_task }}</span>
            </div>
            <div
              @click="hide"
              id="hideTaskAdd"
              style="
                width: 100px;
                text-align: center;
                float: left;
                margin-left: 5px;
                margin-top: -25px;
                cursor: pointer;
              "
              class="btn-danger btn-sm"
            >
              <span>{{ lang.close }}</span>
            </div>
          </div>
          <v-divider></v-divider>
          <div v-if="typeDone == false" :style="`direction:${lang.dir}`">
            <label>{{ lang.taskType }}</label>
            <b-input-group>
                <b-form-select
                  class="selborder"
                  v-model="taskType"
                  :options="tasksTypes"
                  @change="typeOnchange()"
                ></b-form-select>
                <b-input-group-addon style="margin-right: -20px; margin-top: 5px">
                  <i class="fas fa-arrow-down"></i>
                </b-input-group-addon>
              </b-input-group>
          </div>
          <div :style="`direction:${lang.dir}`" v-if="typeDone">
            <v-row>
                <v-col cols="12" md="6" sm="12" v-if="typeDone && (taskType == 2 || taskType == 4)">
                    <label>{{ lang.mobile }}</label>
                    <b-form-input class="inborder" v-model="mobile" @change="getCustomer()" />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="typeDone && (taskType == 2 || taskType == 4)">
                    <label>{{ lang.customer_name }}</label>
                    <b-form-input class="inborder" v-model="customer_name" />
                </v-col>
                <v-col cols="12" md="12" sm="12">
                    <label>{{ lang.task_title }}</label>
                    <b-form-input class="inborder" v-model="title" />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="taskType != 3">
                    <label>{{ lang.start_date }}</label>
                    <b-form-input class="inborder" v-model="start_date" type="date" />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="taskType != 3">
                    <label>{{ lang.start_time }}</label>
                    <b-form-input class="inborder" v-model="start_time" type="time" />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="taskType != 3">
                    <label>{{ lang.deadline }}</label>
                    <b-form-input class="inborder" v-model="deadline" type="date" />
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="taskType != 3">
                    <label>{{ lang.deadline_time }}</label>
                    <b-form-input class="inborder" v-model="deadline_time" type="time" />
                </v-col>
                <v-col cols="12" md="6" sm="12">
                    <label>{{ lang.execute_date }}</label>
                    <b-form-input class="inborder" v-model="execute_date" type="date" />
                </v-col>
                <v-col cols="12" md="6" sm="12">
                    <label>{{ lang.execute_time }}</label>
                    <b-form-input class="inborder" v-model="execute_time" type="time" />
                </v-col>
                <v-col cols="12" md="6" sm="12">
                    <label>{{ lang.agent }}</label>
                        <b-input-group>
                            <b-form-select
                            class="selborder"
                            v-model="agent"
                            :options="agentList"
                            ></b-form-select>
                            <b-input-group-addon style="margin-right: -20px; margin-top: 5px">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="taskType == 2">
                    <label>{{ lang.task_procedure }}</label>
                        <b-input-group>
                            <b-form-select
                            class="selborder"
                            v-model="task_procedure"
                            :options="customer_task_procedure"
                            ></b-form-select>
                            <b-input-group-addon style="margin-right: -20px; margin-top: 5px">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="taskType == 1">
                    <label>{{ lang.task_procedure }}</label>
                        <b-input-group>
                            <b-form-select
                            class="selborder"
                            v-model="inner_task_procedure"
                            :options="innerProcedures"
                            ></b-form-select>
                            <b-input-group-addon style="margin-right: -20px; margin-top: 5px">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                </v-col>
                <v-col cols="12" md="6" sm="12" v-if="taskType == 3">
                    <label>{{ lang.booking_type }}</label>
                        <b-input-group>
                            <b-form-select
                            class="selborder"
                            v-model="booking_type"
                            :options="bookingTypes"
                            ></b-form-select>
                            <b-input-group-addon style="margin-right: -20px; margin-top: 5px">
                            <i class="fas fa-arrow-down"></i>
                            </b-input-group-addon>
                        </b-input-group>
                </v-col>
                <v-col cols="12" md="12" sm="12">
                    <label>{{ lang.notes }}</label>
                    <b-form-textarea class="inborder" v-model="notes" type="time" />
                </v-col>
                <v-col cols="12" md="12" sm="12" style="text-align:end;">
                    <b-button type="button" variant="success" @click="createTask()" class="ma-2 btn-sm" style="">{{lang.save}}</b-button>
                </v-col>
            </v-row>
          </div>
        </template>

        </b-modal>
    </v-form>
</template>

<script>
import axios from 'axios'
import {SnotifyPosition} from 'vue-snotify';
export default {
    component: {},
    data() {
        return {
            typeDone: false,
            taskType: 0,
            mobile: '',
            customer_name: '',
            customer_type: '',
            customerid: 0,
            title: "",
            start_time: "09:00",
            deadline_time: "10:00",
            execute_time: "09:00",
            start_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            deadline: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            execute_date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            agent: '',
            require_procedure: '',
            agents: [],
            notes: '',
            task_procedure: '',
            taskProcedure: [],
            inner_task_procedure: '',
            innerProcedure: [],
            booking_type: '',
            bookingType: [],
        }
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tasksTypes: function(){
            return [
                {text: 'مهمة داخلية', value: 1},
                {text: 'مهمة خاصة بعميل', value:2},
                {text: 'موعد', value: 3},
                {text: 'دعم فني', value: 4},
            ]
        },
        agentList: function(){
            let t = []
            for(let i=0;i<this.agents.length;i++){
                t.push({text: this.agents[i].full_name, value: this.agents[i].id})
            }
            return t;
        },
        customer_task_procedure: function(){
            let t = []
            for(let i=0;i<this.taskProcedure.length;i++){
                t.push({text: this.taskProcedure[i][`name_${this.lang.langname}`], value: this.taskProcedure[i].value})
            }
            return t;
        },
        innerProcedures: function(){
            let t = []
            for(let i=0;i<this.innerProcedure.length;i++){
                t.push({text: this.innerProcedure[i][`name_${this.lang.langname}`], value: this.innerProcedure[i].value})
            }
            return t;
        },
        bookingTypes: function(){
            let t = []
            for(let i=0;i<this.bookingType.length;i++){
                t.push({text: this.bookingType[i][`name_${this.lang.langname}`], value: this.bookingType[i].value})
            }
            return t;
        },
    },
    methods: {
        reset(){
            this.taskType = 0;
            this.typeDone = false;
            this.mobile = '';
            this.customer_name = '';
            this.customer_type = '';
            this.customerid = 0;
            this.title = "";
            this.start_date =  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.deadline =  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.execute_date =  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
            this.start_time = "09:00"
            this.execute_time = "09:00"
            this.deadline_time = "10:00"
            this.agent = '';
            this.require_procedure = '';
            this.notes = '';

            
            this.task_procedure = ''
            this.taskProcedure = []
            this.agents = []
            this.inner_task_procedure = ''
            this.innerProcedure = []
            this.booking_type = ''
            this.bookingType = [],
            this.getSettings();
        },
        getData(){
            return {
                taskType: this.taskType,
                mobile: this.mobile,
                customer_name: this.customer_name,
                customer_type: this.customer_type,
                customerid: this.customerid,
                title: this.title,
                start_date: this.start_date,
                deadline: this.deadline,
                execute_date: this.execute_date,
                start_time: this.start_time,
                execute_time: this.execute_time,
                deadline_time: this.deadline_time,
                agent: this.agent,
                require_procedure: this.require_procedure,
                notes: this.notes,
                task_procedure: this.task_procedure,
                inner_task_procedure: this.inner_task_procedure,
                booking_type: this.booking_type,
            };
        },
        typeOnchange(){
            if(this.taskType != 0){
                this.typeDone = true;
            }else{
                this.typeDone = false;
            }
        },
        getCustomer() {
            const post = new FormData();
            post.append("type","getCustomers");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[mobile]',this.mobile);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    console.log(response.data.results.data);
                    this.customerid = response.data.results.data[0].id;
                    this.customer_type = response.data.results.data[0].customer_type;
                    if(response.data.results.data[0].customer_type == 1)
                        this.customer_name = response.data.results.data[0].full_name;
                    else if(response.data.results.data[0].customer_type == 2)
                        this.customer_name = response.data.results.data[0].company_name;
                    else if(response.data.results.data[0].customer_type == 3)
                        this.customer_name = response.data.results.data[0].entity_name;
                }
            });
        },
        getSettings() {
            const post = new FormData();
            post.append("type","addTaskSettings");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            post.append('data[id]',0);
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    this.agents = response.data.results.agents
                    this.taskProcedure = response.data.results.taskProcedure
                    this.innerProcedure = response.data.results.innerProcedure
                    this.bookingType = response.data.results.bookingType
                }
            });
        },
        createTask(){
            if(this.taskType == 2 || this.taskType == 4){
                if(this.customerid == 0){
                    let message = 'يرجى اضافة العميل من قائمة المبيعات - العملاء ';
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                    return false;
                }
            }
            if(this.title == ''){
                let message = 'يرجى اضافة عنوان المهمة / الموعد ';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.notes == ''){
                let message = 'يرجى اضافة الملاحظات - الوصف ';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.agent == '' || this.agent == 0){
                let message = 'يجب اختيار الموظف / المندوب  ';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            if(this.taskType != 4 && this.require_procedure == '' && this.task_procedure == '' && this.inner_task_procedure == '' && this.booking_type == ''){
                let message = 'يرجى اختيار - الاجراء او نوع الموعد ';
                this.$snotify.error(message, 'تنبيه', {
                    timeout: 1000000,
                    showProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    position: SnotifyPosition.centerCenter,
                    buttons: [
                        {
                            text: this.lang.close, 
                            action: (toast) => {
                                this.$snotify.remove(toast.id); 
                            } 
                        },
                    ]
                });
                return false;
            }
            const post = new FormData();
            post.append("type","doAddTask");
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[lang]',this.$cookies.get(this.$store.state.LangCooki));
            const data = this.getData();
            Object.keys(data).forEach(key => {
                post.append('data['+key+']',data[key]);
            })
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                if(response && response.data && response.data.results){
                    document.getElementById('hideTaskAdd').click();
                    this.$parent.getDaysList()
                    let message = 'تمت الاضافة بنجاح ';
                    this.$snotify.error(message, 'تنبيه', {
                        timeout: 1000000,
                        showProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        position: SnotifyPosition.centerCenter,
                        buttons: [
                            {
                                text: this.lang.close, 
                                action: (toast) => {
                                    this.$snotify.remove(toast.id); 
                                } 
                            },
                        ]
                    });
                }
            });
        }
    },
    created(){
        this.getSettings()
    }
}
</script>
